import React from 'react'
import Layout from '../components/layout-default'
import Breadcrumbs from '../components/breadcrumbs'
import Hero from '../components/hero';

const crumbs = [
    {
        label: "Home",
        link: "",
    },
    {
        label: "Moving",
        link: "moving",
    },
];
const moving = () => {
    return (
        <Layout
            title="Moving | RF Dance"
            description="Discover the best dance studios in Orange County at rfdance.com. From beginners to experts, our classes cater to all levels. Join us and dance like a pro!"
            pathname="moving"
            className="pages"
        // noHero="no-hero"
        >
            <Hero
                titleRed="RF"
                titleWhite="is Moving"
            />
            <div className="content-block">
                <div className="container">
                    <Breadcrumbs crumbs={crumbs} />
                    <div style={{ lineHeight: '1.8' }}>
                        <h2 className='text-center' style={{ fontSize: '40px;' }}>WE ARE MOVING SOON! <br /> Stay Connected!</h2>
                        <p>
                            RF Dance Center is excited to announce our move to a new, more prominent location! <b>Soon you will find us at 2201 W First St, Unit B, Santa Ana, CA 92703.</b> This new studio is conveniently located less than 10 minutes from our current spot, at the intersection of West First St and South Center St. Parking is readily available in the Santa Ana Plaza lot, accessible at the same entrance as the Laundromat. Our entrance faces the plaza.
                        </p>
                        <p>
                            We're thrilled about our new facility's added space, extra bathrooms, and ample parking. Yet, we'll still maintain the same great classes and instructors you've grown to love. Current members will be grandfathered into their current membership rates, while new memberships will cost $149. We can't wait to welcome you to our new home! See the map below for detailed directions.
                        </p>
                        <h4>
                            Location: 2201 W First St, Unit B, Santa Ana, CA 92703
                        </h4>
                        <div style={{ width: '80%', margin: '0 auto' }} className="footer_map_section">
                            <iframe
                                style={{ border: '2px solid black' }}
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13270.147784562974!2d-117.9020127!3d33.7459479!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcd79b8a74b555%3A0x6fcd7e5e3c31e0a6!2s2201%20W%20First%20St%2C%20Unit%20B%2C%20Santa%20Ana%2C%20CA%2092703%2C%20USA!5e0!3m2!1sen!2sus!4v1706659200000!5m2!1sen!2sus"
                                width="100%"
                                height="360"
                                scrolling="no"
                                loading="lazy"
                            ></iframe>
                        </div>
                        <p>To stay entirely in the loop and receive the <b>official move date announcement</b> plus all other move updates, make sure you're signed up for: </p>
                        <ul>
                            <li>
                                <p>
                                    <b>TEXT ALERTS (For the FASTEST Updates): Text "RFDANCE" to 833-661-2074 to join our text list</b> for urgent, immediate announcements, including the move date!
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>EMAIL NEWSLETTER (For Detailed Information): </b> <a href="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-2&subTab=account">Subscribe for comprehensive news and more details.</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>SOCIAL MEDIA (For Fun Sneak Peeks & Community Updates):</b> Follow us on social media!
                                </p>
                                <ul>
                                    <li>
                                        <p><a href="https://www.instagram.com/rfdancecenter/">Instagram</a></p>
                                    </li>
                                    <li>
                                        <p><a href="https://www.facebook.com/RFdanceCenter/">Facebook</a></p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>We are counting down the days with you until we can welcome you to our stunning new dance center! Stay tuned – the date announcement is coming very soon!</p>
                        <h4>IMPORTANT - PLEASE READ: MOVE COMMUNICATION & CLIENT RESPONSIBILITY </h4>
                        <p>Please be advised that RF Dance Center will primarily communicate all move-related updates, including the official move date, class      schedule adjustments, and opening information, via text message and email to clients who opt-in to these services.
                            Each RF Dance Center client is responsible for ensuring they are subscribed to and regularly check text messages and emails for these critical move updates. RF Dance Center will make reasonable efforts to communicate effectively through these channels.</p>
                        <ul>
                            <li>
                                <p>
                                    <b>Failure to check text messages and/or emails and subsequently missing move-related information, including any temporary class disruptions or location changes, will not be grounds for refunds or credits on tuition or memberships. </b>
                                    By continuing enrollment at RF Dance Center, clients acknowledge and agree to this communication policy and the no-refund policy for missed move information.
                                </p>
                            </li>
                        </ul>
                        <p>
                            Don't hesitate to contact RF Dance Center directly for any questions regarding move communications.
                            <br />
                            RF Dance,
                            <br />
                            info@rfdance.com
                            <br />
                            657-293-5161
                            <br />
                            Location: 2201 W First St, Unit B, Santa Ana, CA 92703
                            <br />
                            <a href="https://rfdance.com/moving/">https://rfdance.com/moving/</a>
                        </p>

                        <h2 className='text-center' style={{ fontSize: '40px;' }}>¡NOS MUDAMOS PRONTO! <br />¡Mantente Conectado! </h2>
                        <p>
                            RF Dance Center se complace en anunciar nuestra mudanza a una nueva ubicación, <b>¡más prominente! Próximamente nos encontrarás en 2201 W First St, Unit B, Santa Ana, CA 92703.</b> Este nuevo estudio está convenientemente ubicado a menos de 10 minutos de nuestro local actual, en la intersección de West First St y South Center St. El estacionamiento está fácilmente disponible en el lote de Santa Ana Plaza, accesible por la misma entrada que la lavandería. Nuestra entrada da a la plaza.
                        </p>
                        <p>
                            Estamos encantados con el espacio adicional, los baños extra y el amplio estacionamiento de nuestras nuevas instalaciones. Aún así, mantendremos las mismas clases y instructores excelentes que tanto les gustan. A los miembros actuales se les respetarán sus tarifas de membresía actuales, mientras que las nuevas membresías costarán $149. ¡Estamos ansiosos por darles la bienvenida a nuestro nuevo hogar! Consulta el mapa a continuación para obtener indicaciones detalladas.
                        </p>
                        <h4>Ubicación: 2201 W First St, Unit B, Santa Ana, CA 92703 </h4>
                        <div style={{ width: '80%', margin: '0 auto' }} className="footer_map_section">
                            <iframe
                                style={{ border: '2px solid black' }}
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13270.147784562974!2d-117.9020127!3d33.7459479!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80dcd79b8a74b555%3A0x6fcd7e5e3c31e0a6!2s2201%20W%20First%20St%2C%20Unit%20B%2C%20Santa%20Ana%2C%20CA%2092703%2C%20USA!5e0!3m2!1sen!2sus!4v1706659200000!5m2!1sen!2sus"
                                width="100%"
                                height="360"
                                scrolling="no"
                                loading="lazy"
                            ></iframe>
                        </div>
                        <p>
                            Para mantenerte completamente al tanto y recibir el anuncio oficial de la fecha de mudanza, además de todas las demás actualizaciones sobre la mudanza, asegúrate de suscribirte a:
                        </p>
                        <ul>
                            <li>
                                <p>
                                    <b>ALERTAS DE TEXTO (Para las Actualizaciones MÁS RÁPIDAS): Envía un mensaje de texto con la palabra "RFDANCE" al 833-661- 2074 para unirte a nuestra lista de mensajes de texto</b> y recibir anuncios urgentes e inmediatos, ¡incluyendo la fecha de la mudanza!
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>BOLETÍN INFORMATIVO POR CORREO ELECTRÓNICO (Para Información Detallada):</b> <a href="https://clients.mindbodyonline.com/classic/ws?studioid=222416&stype=-2&subTab=account">Suscríbete para recibir noticias completas y más detalles.</a>
                                </p>
                            </li>
                            <li>
                                <p>
                                    <b>REDES SOCIALES (Para Vistazos Divertidos y Actualizaciones de la Comunidad):</b> ¡Síguenos en redes sociales!
                                </p>
                                <ul>
                                    <li>
                                        <p><a href="https://www.instagram.com/rfdancecenter/">Instagram</a></p>
                                    </li>
                                    <li>
                                        <p><a href="https://www.facebook.com/RFdanceCenter/">Facebook</a></p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            Estamos contando los días con ustedes hasta que podamos darles la bienvenida a nuestro impresionante nuevo centro de danza. ¡Mantente atento – el anuncio de la fecha llegará muy pronto!
                        </p>
                        <h4>IMPORTANTE - POR FAVOR LEA: COMUNICACIÓN SOBRE LA MUDANZA Y RESPONSABILIDAD DEL CLIENTE</h4>
                        <p>
                            Se les informa que RF Dance Center comunicará principalmente todas las actualizaciones relacionadas con la mudanza, incluyendo la fecha oficial de la mudanza, los ajustes en el horario de clases y la información de apertura, a través de mensajes de texto y correo electrónico a los clientes que opten por estos servicios. Cada cliente de RF Dance Center es responsable de asegurarse de estar suscrito y de revisar regularmente los mensajes de texto y correos electrónicos para estas actualizaciones críticas sobre la mudanza. RF Dance Center hará esfuerzos razonables para comunicarse eficazmente a través de estos canales. El no revisar los mensajes de texto y/o correos electrónicos y, en consecuencia, perder información relacionada con la mudanza, incluyendo cualquier interrupción temporal de clases o cambios de ubicación, no será motivo de reembolso o crédito en la matrícula o membresías. Al continuar la inscripción en RF Dance Center, los clientes reconocen y aceptan esta política de comunicación y la política de no reembolso por la información de la mudanza que se haya perdido.
                        </p>
                        <p>
                            No duden en contactar directamente a RF Dance Center si tienen alguna pregunta sobre las comunicaciones de la mudanza.
                            <br />
                            RF Dance, info@rfdance.com
                            <br />
                            657-293-5161
                            <br />
                            Ubicación: 2201 W First St, Unit B, Santa Ana, CA 92703
                            <br />
                            <a href="https://rfdance.com/moving/">https://rfdance.com/moving/</a>
                        </p>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default moving
